const Hotels = [
    {
        src: './img/hotels/hotel1.jpg',
        alt: 'amresorts'
    },
    // {
    //     src: './img/hotels/hotel2.jpg',
    //     alt: 'bahiaprince'
    // },
    {
        src: './img/hotels/hotel3.jpg',
        alt: 'bahiaprince'
    },
    {
        src: './img/hotels/hotel4.jpg',
        alt: 'decameron'
    },
    {
        src: './img/hotels/hotel5.jpg',
        alt: 'hampton'
    },
    {
        src: './img/hotels/hotel6.jpg',
        alt: 'hard rock'
    },
    {
        src: './img/hotels/hotel7.jpg',
        alt: 'hilton'
    },
    {
        src: './img/hotels/hotel8.jpg',
        alt: 'holiday inn'
    },
    {
        src: './img/hotels/hotel9.jpg',
        alt: 'iberostar'
    },
    {
        src: './img/hotels/hotel10.jpg',
        alt: 'barcelo'
    },
    {
        src: './img/hotels/hotel11.jpg',
        alt: 'marriott'
    },
    {
        src: './img/hotels/hotel12.jpg',
        alt: 'melia'
    },
    {
        src: './img/hotels/hotel13.jpg',
        alt: 'palladium'
    },
    {
        src: './img/hotels/hotel14.jpg',
        alt: 'riu'
    },
    {
        src: './img/hotels/hotel15.jpg',
        alt: 'sandals'
    },
    {
        src: './img/hotels/hotel16.jpg',
        alt: 'sheraton'
    },

]

export default Hotels