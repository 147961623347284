import './style.css';

function PSE() {

  return (
    <div className="pse">
      <a href="https://portalpagos.davivienda.com/#/comercio/9099/VIAJES%20CAPITAL" target='_blank' rel="noreferrer">
        <img className="image" src="./img/pse-logo.png" alt="" />
      </a>
    </div>
  );
}

export default PSE;
